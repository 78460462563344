import React from 'react';
import Breadcrumb from '../common/breadcrumb';
import Footer from '../footer/footer';
import HeaderFour from '../header/headerFour';
import InstructorProfile from './instructorProfile';
import { BASE_URL } from '../../../config';
import axios from 'axios'
const Main = ({id}) => {
    const [teacher, setTeacher] = React.useState(null)

    React.useEffect(()=>{
        getTeacher();
    },[id])

    const getTeacher = async () => {
        try {
            const result = await axios.get(BASE_URL+"/teachers/"+id)
            setTeacher(result.data);
        } catch (error) {
            
        }
    }
    return (
        <>
            <HeaderFour />
            <main>
                {teacher &&
                <>
                <Breadcrumb breadcrumbTitle="Profesor" breadcrumbSubTitle={teacher.firstName} />
                <InstructorProfile teacher={teacher} />
                </>

                }
                
            </main>
            <Footer />
        </>
    );
};

export default Main;