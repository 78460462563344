import React from 'react';
import Pagination from '../common/pagination';
import InstructorCourse from './instructorCourse';
import InstructorExperience from './instructorExperience';
import InstructorSkill from './instructorSkill';

const InstructorProfile = ({teacher}) => {
    
    return (
        <>
        {teacher &&
        <>
        <div className="course-details-area pt-120 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-3">
                        <div className="course-instructors-img mb-30">
                            <img className="mb-20" src={teacher.photo} alt="instructors-img" />
                            <div className="course-details-tittle mb-30">
                                <h3>{teacher.firstName} {teacher.lastName}</h3>
                                <span className="d-block mb-15">{teacher.title}</span>
                                <ul>
                                    <li><a href={"mailto:"+teacher.email} target="_blank"><i className="fal fa-envelope"></i> {teacher.email}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-9">
                        <div className="course-details-wrapper">
                            <div className="course-details-meta">
                                <div className="total-course">
                                    <span>Total Cursos</span>
                                    <label>{teacher.courses.length}</label>
                                </div>
                                <div className="student course">
                                    <span>Estudiantes</span>
                                    <label>{teacher.countStudents}</label>
                                </div>
                                {/* <div className="review-course">
                                    <span>Review</span>
                                    <div className="review-course-inner d-flex">
                                        <ul>
                                            <li><a href="#"><i className="fas fa-star"></i></a></li>
                                        </ul>
                                        <p>4.9 (540)</p>
                                    </div>
                                </div> */}
                                <div className="course-details-action">
                                    <div className="course-follow">
                                        <span className="d-block">Sígueme</span>
                                        <div className="member-social">
                                            <ul>
                                                <li><a href={teacher.linkedin} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="course-bio-text pt-45 pb-20">
                                <h3>Bio</h3>
                                <p>{teacher.bio}</p>
                            </div>
                            <InstructorSkill teacher={teacher}/>
                            <InstructorExperience teacher={teacher}/>
                            <InstructorCourse teacher={teacher}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        }
        </>
    );
};

export default InstructorProfile;