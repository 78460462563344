import * as React from "react"
import Layout from "../../components/layout"
import InstructorProfileMain from "../../components/instructorProfile"

const InstructorProfilePage = (props) => (
  <Layout>
    <InstructorProfileMain id={props.params.id} />
  </Layout>
)

export default InstructorProfilePage
