import React from 'react';

const InstructorSkill = ({teacher}) => {
    const {skills} = teacher
    return (
        <>
        {teacher &&
        <>
        <div className="course-bio-text pb-20">
            <h3>Skills</h3>
            <div className="student-reating-bar">
                <div className="reating-bar-wrapper">
                    {skills.map((data,index)=>(
                        <div className="rating-row mb-10" key={index}>
                        <div className="instructor-rating-star">
                            <span>{data.name}</span>
                        </div>
                        <div className="progress">
                            <div className="progress">
                                <div className="progress-bar progress-bar1 wow fadeInLeft" style={{ width: data.progress+"%" }}></div>
                            </div>
                        </div>
                        <div className="progress-tittle">
                            <span>{data.progress}%</span>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
        </>
        }
        </>
    );
};

export default InstructorSkill;