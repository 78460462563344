import { Link } from 'gatsby';
import React from 'react';

const InstructorCourse = ({teacher}) => {
    const {courses} = teacher
    return (
        <>
            <div className="my-course-info">
                <h3>Cursos del profesor</h3>
            </div>
            <div className="row">
            <div className="course-main-items">
                    <div className="tab-content portfolio-tabs-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className='row'>
                                {courses && courses.map((data, index)=>{
                                    return(
                                <div className="col-md-6" key={index}>
                                    <div className="apprende-online-main-wrapper mb-30">
                                        <div className="apprende-online-thumb apprende-online-image-container w-img">
                                            <Link to={"/course-details/"+data.slug}><img src={data.introImage} alt="course-img" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} /></Link>
                                        </div>
                                        <div className="apprende-online-wraper">
                                            <div className="apprende-online-heading">
                                                <Link to="/courses" className="course-link-color-1">{data.category?.name}</Link>
                                                {/* <span className="couse-star"><i className="fas fa-star"></i>{data.reviews} ({data.reviewsCount})</span> */}
                                            </div>
                                            <div className="apprende-online-text">
                                                <h3><Link to={"/course-details/"+data.slug}>{data.name}</Link></h3>
                                            </div>
                                            <div className="apprende-online-meta">
                                                <div className="apprende-online-price">
                                                    <span className="price-now">${data.priceNow} </span>
                                                    <del className="price-old">${data.priceOld}</del>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="apprende-online-footer">
                                            <div className="course-lessson-svg">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.471" height="16.471"
                                                    viewBox="0 0 16.471 16.471">
                                                    <g id="blackboar09" transform="translate(-0.008)">
                                                        <path id="Path_01" data-name="Path 101"
                                                            d="M16,1.222H8.726V.483a.483.483,0,1,0-.965,0v.74H.491A.483.483,0,0,0,.008,1.7V13.517A.483.483,0,0,0,.491,14H5.24L4.23,15.748a.483.483,0,1,0,.836.483L6.354,14H7.761v1.99a.483.483,0,0,0,.965,0V14h1.407l1.288,2.231a.483.483,0,1,0,.836-.483L11.247,14H16a.483.483,0,0,0,.483-.483V1.7A.483.483,0,0,0,16,1.222Zm-.483.965v8.905H.973V2.187Zm0,10.847H.973v-.976H15.514Z"
                                                            fill="#575757" />
                                                    </g>
                                                </svg>
                                                <span className="ms-2">{data.lessons.length} Videos</span>
                                            </div>
                                            <div className="course-deteals-btn">
                                                <Link to={"/course-details/"+data.slug}><span className="me-2">Ver Detalles</span><i className="far fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    )
                                })}
                                
                    
                            </div>
                        </div>                        
                    </div>
                </div>
                
            </div>
        </>
    );
};

export default InstructorCourse;